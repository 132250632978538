var $ = require('jquery');

$.fn.isOnScreen = function(delta){
    var viewport = {};
    viewport.top = $(window).scrollTop();
    viewport.bottom = viewport.top + $(window).height();
    var bounds = {};
    bounds.top = this.offset().top;
    bounds.bottom = bounds.top + this.outerHeight();
    return ((bounds.top + delta <= viewport.bottom) && (bounds.bottom - delta >= viewport.top));
};

$.fn.makeItSticky = function (stickTo, unstickTo) {
    var el = {},
        stickToElement = {},
        unstickToElement = {};

    el.top = this.offset().top;

    stickToElement.top = stickTo.offset().top;
    stickToElement.bottom = stickTo.offset().top + stickTo.height();

    unstickToElement.top = unstickTo.offset().top;
    unstickToElement.bottom = unstickTo.offset().top + unstickTo.height();

    // console.log('stick.bottom', stickToElement.bottom);
    // console.log('unstick.bottom', unstickToElement.bottom);
    //
    // console.log('el.top', el.top);  // Returns 80 on 2nd load

    if(stickToElement.bottom > el.top) {
        this.addClass('sticky');
        // unstickTo.css({paddingBottom: 80})
        console.log('STICK')
    } else if (el.top < unstickToElement.bottom) {
        this.removeClass('sticky');
        // unstickTo.css({paddingBottom: 0})
        console.log('UNSTICK');
    }

    if($('.anchors-submenu').hasClass('sticky')) {
        $('#carousel-visuels').addClass('show-carousel');
        $('#carousel-visuels').removeClass('hidden-carousel');
        $('.owl-item.active').addClass('number-grey');
    } else {
        $('#carousel-visuels').addClass('hidden-carousel');
        $('#carousel-visuels').removeClass('show-carousel');
        $('.owl-item.active').removeClass('number-grey');
    }

}


var shuffle = function (a) {
    var j, x, i;
    for (i = a.length; i; i--) {
        j = Math.floor(Math.random() * i);
        x = a[i - 1];
        a[i - 1] = a[j];
        a[j] = x;
    }
}

var getXY = function (evt, element) {
    var rect = element.getBoundingClientRect();
    var scrollTop = document.documentElement.scrollTop?
        document.documentElement.scrollTop:document.body.scrollTop;
    var scrollLeft = document.documentElement.scrollLeft?
        document.documentElement.scrollLeft:document.body.scrollLeft;
    var elementLeft = rect.left+scrollLeft;
    var elementTop = rect.top+scrollTop;

    x = evt.pageX-elementLeft;
    y = evt.pageY-elementTop;

    return {x:x, y:y};
}

var getCookie = function (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


var setCookie = function (cname, cvalue, exminutes) {
    var d = new Date();
    d.setTime(d.getTime() + (exminutes*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

var checkCookie = function () {
    var user = getCookie("username");
    if (user != "") {

        // Do Stuff if cookie found

    } else {

        // Stuff if cookie not found

        setCookie("username", 'loader', 15)
    }
}

var getRandomInt = function(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}





exports.isOnScreen = $.fn.isOnScreen;
exports.makeItSticky = $.fn.makeItSticky;
exports.shuffle = shuffle;
exports.getXY = getXY;
exports.getRandomInt = getRandomInt;


exports.getCookie = getCookie;
exports.setCookie = setCookie;
exports.checkCookie = checkCookie;