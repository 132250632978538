import './sass/app.scss'
var jQuery = require('jquery');
var $ = require('jquery');
window.$ = window.jQuery = jQuery;
var Barba = require('barba.js');

require('../src/js/components//Svg/Svg.js');
require('../src/js/components/Header/Header');
require('../src/js/components/TypeChirurgie/TypeChirurgie');
require('../src/js/components/PreHome/PreHome');
require('../src/js/components/RowInfo/RowInfo');
require('../src/js/components/Subheader/Subheader');
require('../src/js/components/Submenu/Submenu');
require('../src/js/components/TeamMember/TeamMember');
require('../src/js/components/Map/map');
require('../src/js/components/Transition/Transition');
require('../src/js/components/Views/Views');
require('../src/js/components/Tab/Tab');

var windowScroll = $(window).scrollTop();
var windowHeight = $(window).height();
var lastElementClicked;

Barba.Pjax.init();
Barba.Prefetch.init();


//DOM READY
$(
    function () {


    	Barba.Dispatcher.on('newPageReady', function (currentStatus, oldStatus, container, newPageRawHTML) {

            newPageRawHTML = newPageRawHTML.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', newPageRawHTML);
            if (oldStatus.namespace != "" && oldStatus.namespace != null && oldStatus.namespace != undefined) {
                bodyClasses = $(newPageRawHTML).filter('notbody').removeClass('firstLoad loadingFinished').attr('class');
                $('body').attr('class', bodyClasses);
            }
            ;
            var href = window.location.pathname.substr(1);

        	$('.header__nav__item a[href="'+ href +'"]').addClass('active')


        });

        Barba.Pjax.start()

    }
)