


var $ = require('jquery');
var Barba = require('barba.js');
var rowInfo = require('../RowInfo/RowInfo');
var Utils = require('../../lib/utils');
var Map = require('../Map/map');


var homePage = Barba.BaseView.extend({
  namespace: 'homepage',

  _typeChirurgie: null,
  onEnter: function () {

    // Click function for show the Modal
    $(".mention").on("click", function () {
      $(".mask").addClass("active");
    });
    // Function for close the Modal
    function closeModal() {
      $(".mask").removeClass("active");
    }

    // Call the closeModal function on the clicks/keyboard
    $(".close, .mask").on("click", function () {
      closeModal();
    });

    $(document).keyup(function (e) {
      if (e.keyCode == 27) {
        closeModal();
      }
    });

  },
  onEnterCompleted: function () {
    this._construct();

    this._typeChirurgie._initialize();
  },
  onLeave: function () {
    this._destroy();
  },
  onLeaveCompleted: function () {

    this._destroy();

  },

  _construct: function () {
    this._destroy();


    this._typeChirurgie = require('../TypeChirurgie/TypeChirurgie');

  },

  _destroy: function () {
    this._typeChirurgie = null;
  }
});


var templateChirurgiePage = Barba.BaseView.extend({
  namespace: 'template-chirurgie',
  onEnter: function () {

    if ($(".c-aside-menu")) {
      console.log("if")
      const tabs = document.querySelectorAll(".l-visage__link");
      const contents = document.querySelectorAll(".l-visage__content");


      tabs.forEach((tab) => {
        tab.addEventListener("click", function () {
          const activeTab = document.querySelector(".l-visage__link.-is-active");
          if (activeTab !== null) {
            activeTab.classList.remove("-is-active");
          }


          const dataId = this.getAttribute('data-id');
          this.classList.add("-is-active")

          contents.forEach((content) => {
            if (dataId === content.getAttribute('data-id')) {
              content.classList.add("-is-active");
            } else {
              content.classList.remove("-is-active");
            }
          });

        })
      });
    }

    // Click function for show the Modal
    $(".mention").on("click", function () {
      $(".mask").addClass("active");
    });
    // Function for close the Modal
    function closeModal() {
      $(".mask").removeClass("active");
    }

    // Call the closeModal function on the clicks/keyboard
    $(".close, .mask").on("click", function () {
      closeModal();
    });

    $(document).keyup(function (e) {
      if (e.keyCode == 27) {
        closeModal();
      }
    });

    if ($('#map').length) {
      Map.initMap();
    }

    if ($('#map2').length) {
      Map.initMap2();
    }

    // var data = $('body').data('link')
    // $('.header__nav__link[data-link="'+ data +'"]').addClass('active');    
    // console.log(data);
    // Load map

  },
  onEnterCompleted
    :
    function () {
      this._construct();
      this.start();

    }
  ,
  onLeave: function () {
    this.destroy();
  }
  ,
  onLeaveCompleted: function () {

  }
  ,

  _title: null,
  _letterCount
    :
    null,
  _cumulatedLettersWidth
    :
    0,
  _subheaderVisualWidth
    :
    null,
  _subheaderVisualHeight
    :
    null,
  _subheaderHalfHeight
    :
    null,
  _chosenHalf
    :
    null,
  _partWidth
    :
    null,
  _titleWidth
    :
    null,
  _baseOffset
    :
    null,
  _previousWidth
    :
    0,
  _subMenu
    :
    null,
  _subHeader
    :
    null,
  _header
    :
    null,
  that
    :
    this,

  _initEvents
    :
    function () {

      rowInfo._initialize();
      this._subMenu._initialize();

    }
  ,

  _initPlugins: function () {
    $('.row-info-double__number, .row-info-double__text').matchHeight();
  }
  ,

  _construct: function () {

    this._subMenu = require('../Submenu/Submenu');


    // if ($('.subheader__title').length) {
    //   this._title = new SplitText($('.subheader__title'), { type: "words, chars" });
    //   this._letterCount = this._title.chars.length;
    //   this._cumulatedLettersWidth = 0;
    //   this._subheaderVisualWidth = $('.l-template__img, .subheader__visual').outerWidth();
    //   this._subheaderVisualHeight = $('.l-template__img, .subheader__visual').height();
    //   this._subheaderHalfHeight = this._subheaderVisualHeight / 2;
    //   this._chosenHalf;
    //   this._partWidth = this._subheaderVisualWidth / this._letterCount;

    //   for (var i = 0; i < this._letterCount; i++) {
    //     this._cumulatedLettersWidth = this._cumulatedLettersWidth + this._title.chars[i].clientWidth;
    //   }

    //   this._titleWidth = this._cumulatedLettersWidth;
    // }


    that = this;

  }
  ,

  // _alignLetters: function () {
  //   this._baseOffset = ((this._subheaderVisualWidth - this._titleWidth) / 2 / this._subheaderVisualWidth * 100);
  //   this._previousWidth = 0;

  //   for (var i = 0; i < this._title.chars.length; i++) {

  //     var nX = this._baseOffset,
  //       nY = 50;

  //     TweenMax.to(this._title.chars[i], 1, { css: { left: nX + '%', top: '50%' } })

  //     this._previousWidth = this._title.chars[i].clientWidth / this._subheaderVisualWidth * 100;

  //     this._baseOffset = this._baseOffset + this._previousWidth;
  //   }

  // }
  // ,
  // _randomizeLetters: function () {

  //   for (var i = 0; i < this._title.chars.length; i++) {

  //     // Calc Y coord
  //     if (i % 2 === 0) {

  //       // Values in px
  //       var min = 50, // 50 px from top
  //         max = this._subheaderHalfHeight - 80; // First half height

  //       this._chosenHalf = Utils.getRandomInt(min, max);

  //     } else {
  //       // Values in px
  //       var min = this._subheaderHalfHeight, // Second half height
  //         max = this._subheaderVisualHeight - 80; // 80px from bottom

  //       this._chosenHalf = Utils.getRandomInt(min, max);

  //     }

  //     // Calc x coord.
  //     var currentLetterWidth = this._title.chars[i].clientWidth,
  //       minX = this._partWidth * i + 50,
  //       maxX = this._partWidth * i + this._partWidth - currentLetterWidth,
  //       nX = Math.floor(Utils.getRandomInt(minX, maxX) / this._subheaderVisualWidth * 100), // Convert into %
  //       nY = Math.floor(this._chosenHalf / this._subheaderVisualHeight * 100), // Convert into %
  //       pX = this._title.chars[i].style.left, // Already in % due to alignLetters()
  //       pY = this._title.chars[i].style.top; // Already in % due to alignLetters()


  //     // Translate each letter from its original position to the final one.
  //     TweenMax.set(this._title.chars[i], {
  //       css: { left: nX + '%', top: nY + '%' },
  //       // ease: Sine.easeOut
  //     })

  //   }
  // }
  // ,

  _initMap: function () {
    Map.initMap();
    Map.initMap2();
    console.log('carte chargée');
  }
  ,


  start: function () {
    this._initPlugins();
    this._initEvents();


    // if ($('.subheader__title').length) {
    //   this._randomizeLetters();
    //   setTimeout(function () {
    //     that._alignLetters();
    //   }, 1500)
    // }

    if ($('#map').length) {
      this._initMap();
    }

  }
  ,

  destroy: function () {

    this._subMenu = null;

    $(window).unbind('scroll');

  }

})
  ;

$(
  function () {

    homePage.init();
    templateChirurgiePage.init();

  }
)
