
  var $ = require('jquery');
  require('../../lib/utils');
  
  var _initialize = function () {
  
      var windowWidth = $(window).width();
  
      if ($('.anchors-submenu').length) {
  
          if ($(window).width() > 1024) {
              $('.anchors-submenu').makeItSticky($('.header'), $('.subheader'));
  
              $('.anchors-submenu__link').click(function () {
  
                  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                      var target = $(this.hash);
                      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                      if (target.length) {
                          $('html, body').animate({
                              scrollTop: target.offset().top - 200
                          }, 1000);
                          return false;
                      }
                  }
              });
  
              $(window).resize(function () {
                  windowWidth = $(window).width();
              })
  
              $(window).scroll(function () {
                  if (windowWidth > 1024) {
                      $('.anchors-submenu').makeItSticky($('.header'), $('.subheader'));
                  }
              });
          }
      }
  }
  
  
  exports._initialize = _initialize;
