

  var $ = require('jquery');
  var Snap = require('snapsvg');
  require('../../lib/TweenMax.min');
  // Dom Ready !
  $(
      function () {
          if ($('.nav-toggler__icon').length) {
  
          }
  
          if($('#logo').length) {
              $('#logo').each(function () {
                  var that = $(this);
                  var s = Snap('#logo');
                  Snap.load('./assets/images/icones/logo-og.svg', function (e) {
                      l = e.select('#Calque_1');
                      s.append(l);
                  });
              });
          }
      }
  )
