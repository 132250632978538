
var $ = require('jquery');
var Snap = require('snapsvg');
require('gsap');
require('../../lib/TweenMax.min');
// require('../../lib/splitText.min');
require('../../lib/drawSVG.min');


var windowScroll = $(window).scrollTop();
var windowHeight = $(window).height();
var windowWidth = $(window).width();

var header = $('.header');
var headerBg = $('.header__bg');

var navLinks = $('.header__nav__item');
navLinks = Array.prototype.slice.call(navLinks);
navLinks.reverse();

var navToggler = $('.nav-toggler');
var line = navToggler.find('.nav-toggler__line');
var circle = navToggler.find('.nav-toggler__icon');
var text = navToggler.find('.nav-toggler__text');
var submenu = $('.header__nav__submenu');

var clickHandler = ('ontouchstart' in document.documentElement ? "tap" : "click");

$(
    function () {
        var tlNavToggler = new TimelineMax({ paused: true });
        tlNavToggler.add(TweenMax.from(line, 0.3, { scale: 0, transformOrigin: 'top' }))
            .add(TweenMax.to(line, 0.5, { y: -28, ease: Sine.easeinout }), '-=0.25')
            .add(TweenMax.from('#border-fill', 0.5, { drawSVG: 0 }), "-=0.30")
            .add(TweenMax.to(line, 0.2, { rotation: 90, ease: Sine.easeinout, transformOrigin: 'center' }))
            .add(TweenMax.from(text, 0.3, { x: 25 }))
            .add(TweenMax.from(text, 0.3, { opacity: 0 }), "-=0.15");
            
        var tlHeader = new TimelineMax({ paused: true });
        tlHeader.add(TweenMax.from(headerBg, 0.5, { scaleY: 0 }))
            .add(TweenMax.staggerFrom(navLinks, 0.3, { x: 20, opacity: 0, delay: 0.3 }, 0.05));

        var tlOnlyLinks = new TimelineMax({ paused: true });
        tlOnlyLinks.add(TweenMax.staggerFrom(navLinks, 0.3, { x: 20, opacity: 0, delay: 0.3 }, 0.05));


        if ($('body').hasClass('page-template') && windowWidth >= 1024) {

            $('.header').addClass('active');
            // $('.nav-toggler').addClass('active');
            tlHeader.play();
        }


        $('.nav-toggler').mouseenter(function () {
            tlNavToggler.play();
        });


        $('.header__nav__item a').click(function () {
            $('.header__nav__item a').removeClass("active");
            $(this).addClass("active");

        });


        $('.nav-toggler').click(function () {
          
            if ($('body').hasClass('page-template')) {

                if ($('.header').hasClass('active')) {
                    tlNavToggler.reverse();
                    tlHeader.reverse();
                } else {
                    tlNavToggler.play();
                    $(".c-submenu").css({
                        'visibility': 'hidden',
                        'position': 'absolute'
                    });
                    tlHeader.play();
                }

            } else {

                if ($('.header').hasClass('active')) {
                    tlNavToggler.reverse();
                    tlHeader.reverse();
                } else {
                    tlNavToggler.play();
                    tlHeader.play();
                }

            }

            $('.header').toggleClass('active')
            $(this).toggleClass('active')

        });

        $('.nav-toggler').mouseleave(function () {
            if (!$(this).hasClass('active')) {
                tlNavToggler.reverse();
            }
        });

        $('.header__nav__arrow').click(function() {
            var submenu = $(this).closest('.header__nav__item').find('.c-submenu');
            submenu.css({
                'visibility': 'visible',
                'position': 'relative'
            });
        });

        if (windowWidth < 768) {

            $('.header__nav ul li a').click(function () {
                if ($(this).attr('href') === '#') {
                    event.preventDefault();
                
                }
                else if ($(".header").removeClass('active')) {
                    $(".header").click();
                    $(".c-submenu").css({
                        'visibility': 'hidden',
                        'position': 'absolute'
                    });
                }
                
            });
        }


        $('.header__nav ul li a').mouseenter( function () {
            if ($(this).attr('href') === '#') {
                console.log('hello', this)
                $(this).css({
                    'cursor': 'default',
                });
            
            }
        });

        $('a[href^="#"]').bind(clickHandler, function () {
            var the_id = $(this).attr("href");

            $('html, body').animate({
                scrollTop: $(the_id).offset().top
            }, 'slow');
            return false;
        });




        // Click function for show the Modal
        $(".mention").on("click", function () {
            $(".mask").addClass("active");
        });
        // Function for close the Modal
        function closeModal() {
            $(".mask").removeClass("active");
        }
        // Call the closeModal function on the clicks/keyboard
        $(".close, .mask").on("click", function () {
            closeModal();
        });

        $(document).keyup(function (e) {
            if (e.keyCode == 27) {
                closeModal();
            }
        });



        $(window).resize(function () {
            windowHeight = $(window).height();
            windowWidth = $(window).width();
        });

        $(window).scroll(function () {
            windowScroll = $(window).scrollTop();

            if ($('body').hasClass('home') && windowScroll > windowHeight && windowWidth > 1024) {
                $('.header').addClass('active');
                $('.nav-toggler').addClass('active');
                tlHeader.play();
                tlNavToggler.play();
            }
        });
    }
)


