
  var $ = require('jquery');

  var styles = [{
          "featureType": "all",
          "elementType": "labels.text.fill",
          "stylers": [{
                  "saturation": 36
              },
              {
                  "color": "#000000"
              },
              {
                  "lightness": 40
              }
          ]
      },
      {
          "featureType": "all",
          "elementType": "labels.text.stroke",
          "stylers": [{
                  "visibility": "on"
              },
              {
                  "color": "#000000"
              },
              {
                  "lightness": 16
              }
          ]
      },
      {
          "featureType": "all",
          "elementType": "labels.icon",
          "stylers": [{
              "visibility": "off"
          }]
      },
      {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 20
              }
          ]
      },
      {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 17
              },
              {
                  "weight": 1.2
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 20
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 21
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 17
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 29
              },
              {
                  "weight": 0.2
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 18
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 16
              }
          ]
      },
      {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [{
                  "color": "#000000"
              },
              {
                  "lightness": 19
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [{
                  "color": "#1d1e1f"
              },
              {
                  "lightness": 17
              }
          ]
      }
  ]
  
  // Globally scoped variable
  var map;
  var marker;
  var map2;
  
  var marker2;
  
  // @Google map initialization
  function initMap() {
      if ($('#map').length) {
          var markerLatLng = { lat: 47.2376164, lng: 6.026215699999966 }
  
          var mapOptions = {
              // scrollwheel: false,
              mapTypeId: 'roadmap',
              center: { lat: 47.2376164, lng: 6.026215699999966 },
              zoom: 14,
              disableDefaultUI: true,
          };
          map = new google.maps.Map(document.getElementById('map'), mapOptions);
  
          map.setOptions({ styles: styles });
  
  
          var image = 'assets/images/icones/map-marker.svg';
  
          marker = new google.maps.Marker({
              position: markerLatLng,
              map: map,
              icon: image
  
          })
  
  
          var contentString = '<div id="content">' +
              '<div id="bodyContent">' +
              '<p>Cabinet médical.</p>' +
              '<p>9 rue Moncey, 25000 besancon</p>' +
              '<a target="_blank" href="https://www.google.fr/maps/dir//9+Rue+Moncey,+25000+Besan%C3%A7on/@47.2376164,6.024027,17z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x478d633c11b4cde7:0xcd27170c63e7973b!2m2!1d6.0262157!2d47.2376164">Itinéraires</a>';
  
          var infowindow = new google.maps.InfoWindow({
              content: contentString
          });
          marker.addListener('click', function() {
              infowindow.open(map, marker);
          });
  
          return map;
      }
  }
  
  
  
  function initMap2() {
      if ($('#map2').length) {
          var markerLatLng2 = { lat: 47.2155534, lng: 5.9770453 }
  
          var mapOptions2 = {
              // scrollwheel: false,
              mapTypeId: 'roadmap',
              center: { lat: 47.2155534, lng: 5.9770453 },
              zoom: 16,
              disableDefaultUI: true,
          };
  
  
          map2 = new google.maps.Map(document.getElementById('map2'), mapOptions2);
  
          map2.setOptions({ styles: styles });
          var image2 = 'assets/images/icones/map-marker-clinique.svg';
  
          marker2 = new google.maps.Marker({
              position: markerLatLng2,
              map: map2,
              icon: image2
          })
  
          console.log('initMap2');
  
          var contentString2 = '<div id="content">' +
              '<div id="bodyContent">' +
              '<p>1 rue Rodin, 25000 besancon</p>' +
              '<a target="_blank" href="https://www.google.fr/maps/dir//Polyclinique+de+Franche-Comt%C3%A9,+4+Rue+Auguste+Rodin,+25000+Besan%C3%A7on/@47.2154185,5.9444937,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x478d638834813f55:0x1d61be1ac17eb010!2m2!1d5.979513!2d47.2154238">Itinéraires</a>';
  
          var infowindow2 = new google.maps.InfoWindow({
              content: contentString2
          });
          marker2.addListener('click', function() {
              infowindow2.open(map2, marker2);
          });
  
  
          return map2;
  
  
      }
  
  }
  
  
  
  
  $(
      function() {
  
          if ($('#map').length) {
              initMap();
          }
      }
  )
  
  $(
      function() {
  
          if ($('#map2').length) {
              initMap2();
          }
      }
  )
  
  
  exports.initMap2 = initMap2;
  exports.initMap = initMap;
