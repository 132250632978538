
  var $ = require('jquery');
  require('../../lib/splitText.min');
  var utils = require('../../lib/utils');
  
  function checkCookie () {
      var user = utils.getCookie("username");
  
      if(user != "") {
  
          $('html').removeClass('page-is-loading')
          $('#loader').remove();
  
      } else {
  
          var splitSentence = new SplitText(".pre-home .text-to-appear", {type:"words chars"}),
              letters = Array.prototype.slice.call(splitSentence.chars);
  
          var tlLoader = new TimelineMax({paused: true, onComplete: function () {
              $('html').removeClass('page-is-loading')
              $('#loader').remove();
          }});
  
          tlLoader.add(TweenMax.to('html', 0, {css:{className: '+=page-is-loading'}}))
          tlLoader.add(TweenMax.to('#lel', 2, {css:{'right': 0}, delay: 1}))
          tlLoader.add(TweenMax.to('#logo-empty-container', 0.8, {opacity: 0}))
          tlLoader.add(TweenMax.to('#chargement-bg', 1.5, {scaleX: 0, ease: Circ.easeinout, transformOrigin: 'right'}))
          tlLoader.add(TweenMax.from('.header', 0.8, {opacity: 0}))
          tlLoader.add(TweenMax.staggerFrom(letters, 1.2, {x: -10, opacity: 0, ease: Sine.easeOut}, 0.025))
          tlLoader.play()
  
          utils.setCookie("username", 'loader', 15)
      }
  }
  
  // DOM READY !
  $(
      function () {
  
  
          if($('#loader').length) {
  
            checkCookie();
  
          }
  
  
      }
  )
