
  var $ = require('jquery');
  require('../../lib/TweenMax.min');
  require('../../lib/utils');
  
  var teamMember = $('.team-member');
  var timelines = [];
  
  
  // DOM READY !
  $(
      function () {
          teamMember.each(function (i, e) {
  
              var visualContainer = $(e).find('.team-member__visual');
              var texts = $(e).find('.text-to-animate');
  
              timelines[i] = new TimelineMax();
              timelines[i].add(TweenMax.from(visualContainer, 0.7, {x: -20, opacity: 0}))
              timelines[i].add(TweenMax.staggerFrom(texts, 0.7, {y: 30, opacity: 0}, 0.15), '-=0.20')
                  .pause();
  
              if($(e).isOnScreen(200)) {
                  timelines[i].play();
              }
  
              $(window).scroll(function () {
                  if($(e).isOnScreen(200)) {
                      timelines[i].play();
                  }
              });
  
          });
      }
  )
