
  var $ = require('jquery');
  var Barba = require('barba.js');
  var lastElementClicked;

  Barba.Dispatcher.on('linkClicked', function (el) {
    lastElementClicked = el;

    parent = $(el).parent();

    if (parent.hasClass('subheader__arrow-container')) {
      var href = $(el).attr('href')
      $('.header__nav__item a').removeClass("active");
      $('.header__nav__item a[href="' + href + '"]').addClass('active')
    }


  });


  $(
    function () {

      var TypeToTypeTransition = Barba.BaseTransition.extend({

        start: function () {
          Promise
            .all([this.exit(), this.newContainerLoading])
            .then(this.enter.bind(this));
        },

        finish: function () {
          this.done();
        },


        enter: function () {

          var e = new TimelineMax({
            onComplete: this.finish(),
            onCompleteScope: this
          });

          e.set(this.newContainer, { visibility: 'visible', opacity: 0 });
          e.set(this.oldContainer, { visibility: 'hidden' });

          e.to(this.newContainer, 1, {
            opacity: 1,
          })

          e.set(this.newContainer, { className: '+=no-transform' })


        },

        exit: function () {
          var e = Barba.Utils.deferred(),
            l = new TimelineMax({
              onComplete: function () {
                window.scrollTo(0, 0); // Scroll the window back to top after each transition.
                e.resolve()
              }
            });

          l.set(this.oldContainer, { className: '-=no-transform' })
          l.to(this.oldContainer, 1, { opacity: 0 })


          console.log(e);
          return l.play(), e.promise
        },

        valid: function () {

          return true

        }

      });

      Barba.Pjax.getTransition = function () {

        if (TypeToTypeTransition.valid()) {
          return TypeToTypeTransition;
        }


      }

    }
  )
