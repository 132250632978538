
  var $ = require('jquery');
  require('jquery-match-height');
  require('owl.carousel');
  require('../../lib/utils');
  require('jquery-mousewheel')($);
  
  var timelines = [];
  
  var transitionSlideDuration = 500;
  
  var _initialize = function () {
  
      $('.row-info-double').each(function (i, e) {
  
          var value = $(e).find('.row-info-double__number__value'),
              title = $(e).find('.text-to-appear'),
              text = $(e).find('.row-info-double__text');
  
          timelines[i] = new TimelineMax({paused: true});
          timelines[i].add(TweenMax.to(text, 0.8, {opacity: 1, x: 0}))
              .add(TweenMax.to(value, 0.8, {opacity: 1, x: 0}), '-= 0.8')
              .add(TweenMax.staggerTo(title, 0.8, {y: 0}, 0.3))
  
  
          if ($(e).isOnScreen(300)) {
              timelines[i].play();
          }
  
          $(window).scroll(function () {
              if ($(e).isOnScreen(300)) {
                  timelines[i].play();
              }
          });
  
      });
  
  
  
  
    var owlChiffres = $("#carousel-chiffres");
    var owlVisuels = $("#carousel-visuels");
    var owlCryolipolyse = $("#carousel-cryolipolyse");
  
  
      owlChiffres.owlCarousel({
          center: true,
          pagination: false,
          loop:false,
          mouseDrag: false,
          onChange: function(event) {
  
          },
          onChanged: function(event) {
              var item = event.item.index;
                owlVisuels.trigger('to.owl.carousel', [item, 300]);
          },
          margin: 90,
          smartSpeed: 1200,
          nav: true,
          navText: ["<img src='assets/images/myprevimage.png'>","<img src='assets/images/mynextimage.png'>"],
          responsive:{
            0:{
                items:1
            },
            768:{
                items:3
            }
          }
  
      });
  
      owlVisuels.owlCarousel({
        center: true,
        dots: true,
        dotsContainer: '#carousel-custom-dots',
        pagination: false,
        items:1,
        loop:false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        mouseDrag: false,
        onChange: function(event) {
  
        },
        onChanged: function(event) {
          setTimeout(function () {
              var contentslide = $("#carousel-visuels .owl-item.active .item-alone").data("content");
              // console.log(contentslide);
              $('.infos-slide').fadeOut(transitionSlideDuration);
              setTimeout(function () {
                  $('.infos-slide[data-content="'+ contentslide +'"]').fadeIn(transitionSlideDuration);
              }, transitionSlideDuration + 1)
  
          }, 100);
  
        },
        nav: false
  
    });
  
  
      var startslide = $(".owl-item.active .item-alone").data("content");
  
  
  
        $('a[href^="#"]').click(function(e){        
                var the_id = $(this).attr("href");
                if (the_id && the_id != "#") {
                    $('html, body').animate({
                        scrollTop:$(the_id).offset().top
                    }, 1100);                
                }else{
                    e.preventDefault();
                }
                return false;
        });
  
  
    $('.owl-dot').click(function () {
        owlChiffres.trigger('to.owl.carousel', [$(this).index(), 300]);
    })
  
  
  }
  
  
  
  var optOwl = {};
  
  // DOM READY
  $(function () {
  
  })
  
  exports._initialize = _initialize;  
