

var $ = require('jquery');
require('../../lib/TweenMax.min');
require('../../lib/utils');


var _initialize = function () {

    var timelines = [];
    var typeChirurgie = $('.type-chirurgie');
    var windowW = $(window).width();

    typeChirurgie.each(function (i, e) {
        var rightLine = $(e).find('.decoration-line.right'),
            leftLine = $(e).find('.decoration-line.left'),
            textLines = $(e).find('.text-to-appear'),
            mask = $(e).find('.type-chirurgie__mask');

        timelines[i] = new TimelineMax({paused: true});
        timelines[i].add(TweenMax.from(mask, 1, {height: 0, ease: Power2.easeInOut, transformOrigin: 'bottom'}))
            .add(TweenMax.to(rightLine, 0.5, {y: 0}))
            .add(TweenMax.to(leftLine, 0.5, {y: 0}), '-=0.7')
            .add(TweenMax.staggerTo(textLines, 0.6, {y: 0, ease: Circ.easeOut}, 0.15), "+=0.2");

        $(window).resize(function () {
            windowW = $(window).width();
        });

        if ($(e).isOnScreen(300)) {
            timelines[i].play();
        }

        $(window).scroll(function () {
            if ($(e).isOnScreen(300)) {
                timelines[i].play();
            }
        });

    });
}

exports._initialize = _initialize;
