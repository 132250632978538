
  // var $ = require('jquery');
  // var Utils = require('../../lib/utils');
  // //
  // //
  // // var title = new SplitText($('.subheader__title'), {type: 'words, chars'});
  // // var letterCount = subheaderTitle.chars.length;
  // // var cumulatedLettersWidth = 0;
  // //
  // // for (var i = 0; i < letterCount; i++) {
  // //     cumulatedLettersWidth = cumulatedLettersWidth + subheaderTitle.chars[i].clientWidth;
  // // }
  // //
  // // var subheaderVisualWidth = $('.subheader__visual').outerWidth();
  // // var subheaderVisualHeight = $('.subheader__visual').height();
  // // var subheaderHalfHeight = subheaderVisualHeight / 2;
  // //
  // // var chosenHalf;
  // //
  // // var partWidth = subheaderVisualWidth / letterCount;
  // //
  // // var titleWidth = cumulatedLettersWidth;
  // //
  // // function randomizeLetters() {
  // //
  // //     $('.subheader__title').removeClass('align-letters');
  // //
  // //     for (var i = 0; i < subheaderTitle.chars.length; i++) {
  // //
  // //         // Calc Y coord
  // //         if (i % 2 === 0) {
  // //
  // //             // Values in px
  // //             var min = 50, // 50 px from top
  // //                 max = subheaderHalfHeight - 80; // First half height
  // //
  // //             chosenHalf = Utils.getRandomInt(min, max);
  // //
  // //         } else {
  // //             // Values in px
  // //             var min = subheaderHalfHeight, // Second half height
  // //                 max = subheaderVisualHeight - 80; // 80px from bottom
  // //
  // //             chosenHalf = Utils.getRandomInt(min, max);
  // //
  // //         }
  // //
  // //         // Calc x coord.
  // //         var currentLetterWidth = subheaderTitle.chars[i].clientWidth,
  // //             minX = partWidth * i + 50,
  // //             maxX = partWidth * i + partWidth - currentLetterWidth
  // //
  // //         var nX = Math.floor(Utils.getRandomInt(minX, maxX) / subheaderVisualWidth * 100); // Convert into %
  // //         var nY = Math.floor(chosenHalf / subheaderVisualHeight * 100); // Convert into %
  // //
  // //
  // //         var pX = subheaderTitle.chars[i].style.left; // Already in % due to alignLetters()
  // //         var pY = subheaderTitle.chars[i].style.top; // Already in % due to alignLetters()
  // //
  // //
  // //         // Translate each letter from its original position to the final one.
  // //         TweenMax.fromTo(subheaderTitle.chars[i], 1, {css: {left: pX, top: pY}}, {
  // //             css: {left: nX + '%', top: nY + '%'},
  // //             ease: Sine.easeOut
  // //         })
  // //
  // //     }
  // // }
  // //
  // // function alignLetters() {
  // //
  // //     var baseOffset = (( subheaderVisualWidth - titleWidth ) / 2 / subheaderVisualWidth * 100);
  // //     var previousWidth = 0;
  // //
  // //     for (var i = 0; i < subheaderTitle.chars.length; i++) {
  // //
  // //         var nX = baseOffset;
  // //         var nY = 50;
  // //
  // //         TweenMax.set(subheaderTitle.chars[i], {css: {left: nX + '%'}})
  // //
  // //         previousWidth = subheaderTitle.chars[i].clientWidth / subheaderVisualWidth * 100;
  // //
  // //         baseOffset = baseOffset + previousWidth;
  // //     }
  // // }
  // //
  // //
  // // var _initialize = function () {
  // //
  // //     alignLetters();
  // //
  // //     setTimeout(function () {
  // //         randomizeLetters();
  // //     }, 800)
  // // }
  // //
  // // exports._initialize = _initialize;
  
  // var subheader = {
  
  //     init: function () {
  //         this.title = new SplitText($('.subheader__title'), {type: 'word, chars'});
  //         this.letterCount = this.title.chars.length;
  //         this.cumulatedLettersWidth = 0;
  //         this.subheaderVisualWidth = $('.subheader__visual').outerWidth();
  //         this.subheaderVisualHeight = $('.subheader__visual').height();
  //         this.subheaderHalfHeight = this.subheaderVisualHeight / 2;
  //         this.chosenHalf;
  //         this.partWidth = this.subheaderVisualWidth / this.letterCount;
  //         this.titleWidth = this.cumulatedLettersWidth;
  //     },
  
  //     align: function () {
  //         var baseOffset = (( this.subheaderVisualWidth - this.titleWidth ) / 2 / this.subheaderVisualWidth * 100);
  //         var previousWidth = 0;
  
  //         for (var i = 0; i < this.title.chars.length; i++) {
  
  //             var nX = baseOffset;
  //             var nY = 50;
  
  //             TweenMax.set(this.title.chars[i], {css: {left: nX + '%'}})
  
  //             previousWidth = this.title.chars[i].clientWidth / this.subheaderVisualWidth * 100;
  
  //             baseOffset = baseOffset + previousWidth;
  //         }
  //     },
  
  //     randomize: function () {
  //         $('.subheader__title').removeClass('align-letters');
  
  //         for (var i = 0; i < this.title.chars.length; i++) {
  
  //             // Calc Y coord
  //             if (i % 2 === 0) {
  
  //                 // Values in px
  //                 var min = 50, // 50 px from top
  //                     max = this.subheaderHalfHeight - 80; // First half height
  
  //                 this.chosenHalf = Utils.getRandomInt(min, max);
  
  //             } else {
  //                 // Values in px
  //                 var min = this.subheaderHalfHeight, // Second half height
  //                     max = this.subheaderVisualHeight - 80; // 80px from bottom
  
  //                 this.chosenHalf = Utils.getRandomInt(min, max);
  
  //             }
  
  //             // Calc x coord.
  //             var currentLetterWidth = this.title.chars[i].clientWidth,
  //                 minX = this.partWidth * i + 50,
  //                 maxX = this.partWidth * i + this.partWidth - this.currentLetterWidth
  
  //             var nX = Math.floor(Utils.getRandomInt(minX, maxX) / this.subheaderVisualWidth * 100); // Convert into %
  //             var nY = Math.floor(this.chosenHalf / this.subheaderVisualHeight * 100); // Convert into %
  
  
  //             var pX = this.title.chars[i].style.left; // Already in % due to alignLetters()
  //             var pY = this.title.chars[i].style.top; // Already in % due to alignLetters()
  
  
  //             // Translate each letter from its original position to the final one.
  //             TweenMax.fromTo(this.title.chars[i], 1, {css: {left: pX, top: pY}}, {
  //                 css: {left: nX + '%', top: nY + '%'},
  //                 ease: Sine.easeOut
  //             })
  //         }
  //     }
  // }
  
  // exports.subheader = subheader;
